import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/app/src/app/[locale]/layout.module.scss");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"variable\":\"--font-sans\",\"weight\":[\"400\",\"500\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Source_Code_Pro\",\"arguments\":[{\"variable\":\"--font-mono\",\"weight\":[\"400\",\"700\"],\"subsets\":[\"latin\"]}],\"variableName\":\"sourceCodePro\"}");
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/libs/fonts.ts\",\"import\":\"Montserrat\",\"arguments\":[{\"variable\":\"--font-title\",\"weight\":\"900\",\"subsets\":[\"latin\"]}],\"variableName\":\"montserrat\"}");
import(/* webpackMode: "eager" */ "/app/src/components/common/footer.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/header.tsx");
import(/* webpackMode: "eager" */ "/app/src/components/ui/link.module.scss");
import(/* webpackMode: "eager" */ "/app/src/components/common/socials.module.scss");
import(/* webpackMode: "eager" */ "/app/src/contexts/i18n-context.tsx");
import(/* webpackMode: "eager" */ "/app/src/styles/reset.scss")